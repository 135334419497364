/*
 *
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: MIT-0
 */
import { ThemeProvider, defaultDarkModeOverride } from '@aws-amplify/ui-react';
import { Alert } from '@cloudscape-design/components';
import { Mode } from '@cloudscape-design/global-styles';
import { Amplify } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { AppConfig, DemoDetails } from '../types';
import { AppContext } from './app-context';
import { StorageHelper } from './storage-helper';
import App from '../App';
import AmazonAuthenticator from './amazon-auth';
import { DemoContext } from './demo-context';

export default function AppConfigured() {
  const [config, setConfig] = useState<AppConfig | null>(null);
  const [demos, setDemos] = useState<DemoDetails[]>([]);
  const [error, setError] = useState<boolean | null>(null);
  const [theme, setTheme] = useState(StorageHelper.getTheme());
  useEffect(() => {
    (async () => {
      try {
        const result = await fetch('/amplifyconfiguration.json');
        const awsExports = (await result.json()) as AppConfig | null;
        Amplify.configure({
          ...awsExports,
        });
        setConfig(awsExports);
      } catch (e) {
        setError(true);
        console.error(e);
      }
      try {
        const demoResult = await fetch('/demos.json');
        const demoData = (await demoResult.json()) as DemoDetails[];
        setDemos(demoData);
      } catch (e) {
        setError(true);
        console.error(e);
      }
    })().catch((e) => {
      setError(true);
      console.error(e);
    });
  }, []);
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          const newValue =
            document.documentElement.style.getPropertyValue(
              '--app-color-scheme',
            );

          const mode = newValue === 'dark' ? Mode.Dark : Mode.Light;
          if (mode !== theme) {
            setTheme(mode);
          }
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['style'],
    });

    return () => {
      observer.disconnect();
    };
  }, [theme]);

  if (!config) {
    if (error) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Alert header="Configuration error" type="error">
            Error loading configuration from "
            <a href="/amplifyconfiguration.json" style={{ fontWeight: '600' }}>
              /amplifyconfiguration.json
            </a> and
            <a href="/demos.json" style={{ fontWeight: '600' }}>
              /demos.json
            </a>
            "
          </Alert>
        </div>
      );
    }
    return <div>Loading</div>;
  }

  return (
    <AppContext.Provider value={config}>
      <DemoContext.Provider value={demos}>
        <ThemeProvider
          theme={{
            name: 'default-theme',
            overrides: [defaultDarkModeOverride],
          }}
          colorMode={theme === Mode.Dark ? 'dark' : 'light'}
        >
          <AmazonAuthenticator>
            <App />
          </AmazonAuthenticator>
        </ThemeProvider>
      </DemoContext.Provider>
    </AppContext.Provider>
  );
}
