import '@aws-amplify/ui-react/styles.css';
import { AppLayout, ContentLayout, Header, HelpPanel, SpaceBetween, TopNavigation } from '@cloudscape-design/components';
import '@cloudscape-design/global-styles/index.css';
import { signOut } from 'aws-amplify/auth';
import { useCallback, useContext } from 'react';
import { AppContext } from './components/app-context';
import { DemoTable } from './components/demo-table';
import { UserContext } from './components/user-context';


export default function App() {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const handleSignOut = useCallback(async () => {
    console.log('Signing out');
    console.log(`Redirect URL: ${appContext?.Auth.Cognito.loginWith?.oauth?.redirectSignOut?.[0]}`);
    await signOut({
      global: true,
      oauth: {
        redirectUrl: appContext?.Auth.Cognito.loginWith?.oauth?.redirectSignOut?.[0],
      },
    });
  }, [appContext]);

  return (
    <SpaceBetween size="s" direction='vertical'>
      <TopNavigation
        identity={{
          href: '/',
          title: 'EdTech GenAI Demo Portal',
        }}
        utilities={[
          {
            type: 'button',
            text: `Hi, ${userContext?.userGivenName ?? 'User'}`,
          },
          {
            type: 'button',
            text: 'Logout',
            onClick: handleSignOut,
            external: false,
          },
        ]}
      />
      <AppLayout
        navigationHide={true}
        tools={<HelpPanel header={<h2>EdTech GenAI Demos Portal Help</h2>}>
          <p>Welcome to the EdTech GenAI Demos Portal. This portal is a collection of common POC demos for customer demos.</p>
          <p>This portal is for Amazonian use only.</p>
          <p>If you run into any challenges, please contact awsrudy@amazon.com</p>
        </HelpPanel>}
        content={<ContentLayout

          header={<Header variant='awsui-h1-sticky' description='Common POC Demos for Customer Demos.'>
              EdTech GenAI Demos Portal
          </Header> }
        >
          <SpaceBetween direction='vertical' size='m'>
            <DemoTable/>
          </SpaceBetween>
        </ContentLayout>
        }
      />
    </SpaceBetween>
  );
}

