/*
 *
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: MIT-0
 */
import { Button, Container, Header, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { Amplify } from 'aws-amplify';
import { fetchUserAttributes, getCurrentUser, signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AppContext } from './app-context';
import { UserContext, userContextDefault } from './user-context';

export default function AmazonAuthenticator (props: PropsWithChildren) {
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState(userContextDefault.userId);
  const [userGivenName, setUserGivenName] = useState(
    userContextDefault.userGivenName ?? '',
  );
  const [userFamilyName, setUserFamilyName] = useState(
    userContextDefault.userFamilyName ?? '',
  );
  const { children } = props;
  const clearUser = () => {
    setUserId('');
    setUserGivenName('');
    setUserFamilyName('');
  };

  useEffect(() => {
    setIsLoading(true);
    if (!appContext) {
      return;
    }
    const setUser = () => {
      try {
        getCurrentUser().then(() => {
          fetchUserAttributes().then((attributes) => {
            if (attributes.given_name) {
              setUserGivenName(attributes.given_name);
            }
            if (attributes.family_name) {
              setUserFamilyName(attributes.family_name);
            }
            if (attributes.sub) {
              setUserId(attributes.sub);
            } else {
              console.error('No sub found in user attributes');
            }
          }).catch((error) => {
            console.error(error);
          });
          fetchAuthSession().then((session) => {
            const accessToken = session.tokens?.accessToken;
            if (accessToken) {
              document.cookie = `auth_token=${accessToken}; path=/; secure; samesite=strict`;
            } else {
              console.error('No access token found in session');
            }
          }).catch((error) => {
            console.debug(error);
          });
        }).catch((error) => {
          console.debug(error);
        });
      } catch (error) {
        //Error is okay
      } finally {
        setIsLoading(false);
      }
    };

    if (appContext.Auth.Cognito.loginWith?.oauth) {
      setUser();
      Hub.listen('auth', ({ payload }) => {
        switch (payload.event) {
          case 'signInWithRedirect':
            console.log('Sign in with redirect');
            setUser();
            break;
          case 'signedOut':
            try {
              setUser();
            } catch (error) {
              console.error(error);
            }
            break;
        }
      });
    } else {
      Hub.listen('auth', ({ payload }) => {
        if (payload.event === 'signedOut') {
          clearUser();
        }
        if (payload.event === 'signedIn') {
          try {
            setUser();
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
    try {
      setUser();
    } catch (error) {
      //ERROR IS OKAY
    }
  }, [appContext]);


  const handleFederateClick = () => {
    if (appContext === null) {
      console.log('No appContext');
      return;
    }
    if (!appContext.Auth.Cognito.loginWith?.oauth) {
      console.log('No oauth configured');
      return;
    }
    if (appContext.Auth.Cognito.loginWith?.oauth?.providers !== undefined) {
      const provider = appContext.Auth.Cognito.loginWith?.oauth?.providers[0];
      if (provider != undefined) {
        console.log(provider.toString());
        Amplify.configure({ Auth: appContext.Auth });
        signInWithRedirect({
          provider: {
            custom: provider.toString(),
          },
        }).catch((error) => {
          console.error(error);
        });

      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        userGivenName,
        setUserGivenName,
        userFamilyName,
        setUserFamilyName,
      }}
    >
      {isLoading ? (
        <SpaceBetween direction="vertical" size="l" alignItems="center">
          <Container>
            <SpaceBetween size="l" direction="vertical" alignItems="center">
              <h2>Loading</h2>
              <Spinner size="big" />
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      ) : userId.length < 1 ? (
        <SpaceBetween direction="vertical" size="l" alignItems="center">
          <Container
            header={<Header description="Click to Federate">Sign In</Header>}
          >
            <Button variant="primary" onClick={handleFederateClick}>
          Federate
            </Button>
          </Container>
        </SpaceBetween>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
}
