import { Badge, Cards, Link } from '@cloudscape-design/components';
import { useContext, useState } from 'react';
import { DemoDetails, DemoStatus } from '../types';
import { DemoContext } from './demo-context';


export function DemoTable() {
  const items = useContext(DemoContext);
  const [selectedItems, setSelectedItems] = useState<DemoDetails[]>([]);
  return (
    <Cards
      onSelectionChange={({ detail }) =>
        setSelectedItems(detail?.selectedItems ?? [])
      }
      items={items}
      selectedItems={selectedItems}
      cardDefinition={{
        header: (item: DemoDetails) => item.status !== DemoStatus.comingSoon ? <Link
          external
          href={`/live-demos/${item.urlSlug}`}
        >{item.name}</Link> : <Link>{item.name}</Link>,
        sections: [
          {
            id: 'image',
            content: (item: DemoDetails) => {
              if (item.imageUrl) {
                return <img
                  alt={item.name}
                  src={item.imageUrl}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />;
              }
              return null;
            },
          },
          {
            id: 'description',
            content: (item: DemoDetails) => item.description,
          },
          // {
          //   id: 'tags',
          //   header: 'Tags',
          //   content: (item: DemoDetails) => item.tags?.join(', ') ?? <Badge>None</Badge>,
          // },
          {
            id: 'status',
            header: 'Status',
            content: (item: DemoDetails) => {
              switch (item.status) {
                case DemoStatus.development:
                  return <Badge color='severity-low'>In Development</Badge>;
                case DemoStatus.staging:
                  return <Badge color='blue'>Pre-Release</Badge>;
                case DemoStatus.comingSoon:
                  return <Badge color='severity-neutral'>Demo Coming Soon</Badge>;
                case DemoStatus.production:
                default:
                  return <Badge color='green'>Ready to Demo</Badge>;
              }
            },
          },
        ],
      }}
    />
  );
}