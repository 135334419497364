import { AuthConfig } from '@aws-amplify/core';
import { Dispatch, SetStateAction } from 'react';

export enum DemoStatus {
  development,
  staging,
  production,
  comingSoon,
}

export interface DemoDetails {
  name: string;
  description?: string;
  tags?: string[];
  urlSlug: string;
  imageUrl?: string;
  videoUrl?: string;
  status?: DemoStatus;
}

export interface AppConfig {
  Auth: AuthConfig;
}


export interface UserData {
  userId: string;
  userGivenName?: string;
  userFamilyName?: string;
  setUserId: Dispatch<SetStateAction<string>>;
  setUserGivenName: Dispatch<SetStateAction<string>>;
  setUserFamilyName: Dispatch<SetStateAction<string>>;
}

export interface NavigationPanelState {
  collapsed?: boolean;
  collapsedSections?: Record<number, boolean>;
}