import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import AppConfigured from './components/app-config-wrapper';

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <AppConfigured />
  </React.StrictMode>,
);
